(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/app-notifications/assets/javascripts/app-notifications.js') >= 0) return;  svs.modules.push('/components/components/app-notifications/assets/javascripts/app-notifications.js');

var svs = svs || {};
svs.components = svs.components || {};
svs.components.app_notifications = svs.components.app_notifications || {};

(function(svs) {
  'use strict';

  var logger = svs.core.log.getLogger('component:app-notifications');

  function enterRoom(data) {
    var options = {
      method: 'POST',
      path: '/notification/2/playersubscriptions/wagers',
      data: data
    };
    svs.core.jupiter.call(
      options,
      function() {
        logger.debug('Has set up notifications for: ' +
            data.product +
            ', number of rooms: ' +
            data.roomKeyList.length +
            ', expires: ' +
            data.expires +
            ', wager: ' +
            data.serial);
      },
      function(xhr) {
        if (xhr.status === 404) {
          logger.debug('Customer has no notification device');
        } else {
          var errMsg = 'No error Message';
          if (xhr.responseJSON && xhr.responseJSON.error) {
            errMsg = xhr.responseJSON.error.message || errMsg;
          }
          logger.error(
            'Failed to set up notifications: ' + decodeURI(errMsg),
            data
          );
        }
      }
    );
  }

  function setUpNativeAppsNotifications(appNotifications) {
    var productName = svs.utils.products.getProdName(appNotifications.productId);
    var wagerSerial = appNotifications.wagerSerial;
    var roomKeys = appNotifications.roomKeys;
    var data;
    if (!productName) {
      logger.error('Missing product name for: ' + appNotifications.productId);
    } else if (!wagerSerial) {
      logger.error('Missing wagerSerial');
    } else if (!roomKeys) {
      logger.debug('Missing room keys object');
    } else if (
      !roomKeys.keys ||
      !roomKeys.keys.length ||
      roomKeys.keys.length === 0
    ) {
      logger.error('No keys in room key object');
    } else if (!roomKeys.timeToLive) {
      logger.error('Missing time to live in room key object');
    } else {
      data = {
        roomKeyList: roomKeys.keys,
        product: productName,
        serial: wagerSerial,
        expires: roomKeys.timeToLive,
        mediaTypeId: 20, 
        token: {
          url: '/mina-spel'
        }
      };
      if (
        svs.utils.ns.isNamespace('svs.utils.nativeAppDeviceHandler', window)
      ) {
        if (svs.utils.nativeAppDeviceHandler.updatingDeviceDone()) {
          enterRoom(data);
        } else {
          svs.utils.nativeAppDeviceHandler.on(
            'updatingNotificationDevicesDone',
            function() {
              enterRoom(data);
            }
          );
        }
      } else {
        enterRoom(data);
      }
    }
  }

  svs.components.app_notifications.setUpAppNotifications = setUpNativeAppsNotifications;
})(svs);


 })(window);